<template>
  <h3 class="hidden">Check out partials/header.vue</h3>
</template>

<script>

export default {
  name: 'home',
  metaInfo () {
    return {
      title: this.$t('page.home'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  }
}
</script>
